@charset "UTF-8";[data-rk] .iekbcc0 {
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent
}

@keyframes _1am14411 {
  0% {
      stroke-dashoffset: 0
  }

  to {
      stroke-dashoffset: -283
  }
}

.rise {
  color: #37e2c0!important
}

.fall {
  color: #ff73ce!important
}

.blue {
  color: #33ceff!important
}

.green,.red {
  color: #ff73ce!important
}

.purple {
  color: #d028fa!important
}

.pink {
  color: #d633ff!important
}

.yellow {
  color: #ffcb1e!important
}

.orange {
  color: #ff7001!important
}

.cyan {
  color: #35fffe!important
}

body,ul,ol,dl,dd,h1,h2,h3,h4,h5,h6,pre,form,fieldset,legend,input,button,textarea,p,blockquote,table,th,td,menu {
  margin: 0;
  padding: 0;
  resize: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

ul,ol,menu {
  list-style: none
}

fieldset,img {
  border: none
}

img,object,select,input,textarea,button {
  vertical-align: middle
}

input,textarea,select,address,caption,cite,code,dfn,em,i,b,strong,small,th,var,abbr,button,u,s,del {
  font-style: normal;
  text-decoration: none
}

article,aside,footer,header,hgroup,nav,section,figure,figcaption {
  display: block
}

code,kbd,pre,samp,tt {
  font-family: Consolas,Courier New,Courier,monospace
}

address,cite,dfn,em,var,i {
  font-style: normal
}

blockquote,q {
  quotes: none
}

blockquote:before,blockquote:after,q:before,q:after {
  content: "";
  content: none
}

a {
  text-decoration: none;
  transition: all .25s ease-in-out;
  cursor: pointer;
  color: #6e4827
}

a:hover,a:focus {
  outline: none;
  color: #33ceff
}

a.stone {
  color: #33ceff
}

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box
}

*:focus {
  outline: none
}

path {
  cursor: pointer
}

abbr[title],acronym[title] {
  border-bottom: 1px dotted;
  cursor: help
}

body {
  margin: 0!important;
  padding: 0!important
}

input,textarea,select,button {
  font-family: Orbitron,PingFang SC,Heiti SC,Droidsansfallback,Helvetica,Droid Sans;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  outline: none;
  -webkit-appearance: none;
  color: #222
}

input[type=radio],textarea[type=radio],select[type=radio],button[type=radio] {
  -webkit-appearance: radio
}

input[type=checkbox],textarea[type=checkbox],select[type=checkbox],button[type=checkbox] {
  -webkit-appearance: checkbox
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none
}

input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none
}

.dark input,.dark textarea,.dark select,.dark button {
  color: #888
}

html {
  color: #6e4827;
  background: url(//rocket-dog-static.pages.dev/image/pc-bg.jpg) center no-repeat,url(//rocket-dog-static.pages.dev/image/pc-bg-x.png) center top repeat-y,url(//rocket-dog-static.pages.dev/image/pc-bg-y.png) center top repeat-x #342b1c;
}


body {
  line-height: 1;
  font-size: 14px
}

body>iframe {
  display: none!important;
  z-index: 0!important;
  position: static!important
}

body * {
  font-family: Orbitron!important
}

.block {
  display: block
}

.none {
  display: none
}

.clear {
  clear: both
}

.fl {
  float: left
}

.fr {
  float: right
}

.tl {
  text-align: left
}

.tr {
  text-align: right!important
}

.tc {
  text-align: center
}

.pr {
  position: relative
}

.inline-flex {
  display: inline-flex;
  gap: 5px
}

.ai-center {
  align-items: center
}

.cf {
  zoom:1}

.cf:before,.cf:after {
  content: "";
  display: table
}

.cf:after {
  clear: both
}

.cup {
  cursor: pointer
}

.web,.p-home-rules,.p-home-bench,.p-home-banner,.p-home-list,.c-header,.c-footer {
  width: 1240px;
  margin-left: auto;
  margin-right: auto
}

.web_ {
  min-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  width: 100%
}


::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: #0e0c15
}

::-webkit-scrollbar-thumb {
  background: #303044;
  border-radius: 0
}

::-webkit-scrollbar-track-piece {
  background: #0e0c15
}

::-webkit-scrollbar-corner {
  background: #0e0c15
}

.toaster {
  min-width: auto;
  font-size: 13px;
  font-weight: 700;
  border: 2px solid #ffa108;
  background: linear-gradient(180deg,#533129,#170c1f)!important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #fff!important
}



@keyframes load {
  0% {
      opacity: 1
  }

  to {
      opacity: .2
  }
}

@keyframes wait-modal {
  0% {
      left: -50px;
      width: 50px
  }

  50% {
      left: calc(50% - 100px);
      width: 200px
  }

  to {
      left: 100%;
      width: 50px
  }
}

@keyframes loading {
  0% {
      transform: scale(1)
  }

  to {
      transform: scale(1.5)
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

.c-blank {
  display: block;
  clear: both
}

.c-color-box {
  padding: 9px;
  background: #151527;
  border-radius: 20px
}

.c-color-box-layout {
  border-radius: 20px;
  padding: 1px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px)
}

.c-color-box-layout.default {
  background-image: linear-gradient(0deg,#d633ff6c 56.28%,#33ceffd9)
}

.c-color-box-layout.ring {
  background: conic-gradient(from 180deg at 50% 50%,#ff98e2,#ffc876 35.77deg,#79fff7 153.75deg,#9f53ff 258.75deg,#ff98e2 360deg)
}

.c-color-box-layout.ring .c-color-box {
  background: #111
}

.c-toggle-box {
  position: absolute;
  z-index: -1;
  height: 0;
  overflow: hidden
}

.c-toggle-box.show {
  position: relative;
  z-index: auto;
  height: auto
}

.c-button {
  border: none;
  cursor: pointer;
  transition: all .25s ease-in-out;
  font-weight: 700;
  position: relative
}

.c-button,.c-button>span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap
}

.c-button-type-default {
  background: linear-gradient(180deg,#fef102,#ffa108);
  color: #6e4827
}

.c-button-type-default:hover,.c-button-type-default:focus {
  opacity: .8
}

.c-button-type-default.disabled {
  opacity: .5
}

.c-button-type-dark {
  border-radius: 100px;
  border: 1px solid #a77fb3;
  background: #d028fa1f;
  box-shadow: 0 -8px 20px #605d8999 inset;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: #6e4827
}

.c-button-type-dark:hover,.c-button-type-dark:focus {
  background: #303044
}

.c-button-type-dark.disabled {
  background: #0f0f0f80;
  color: #9f9f9f
}

.c-button-size-super {
  height: 64px;
  padding: 0 56px;
  font-size: 22px;
  border-radius: 16px
}

.c-button-size-super,.c-button-size-super>span {
  gap: 8px
}

.c-button-size-super.round {
  border-radius: 32px
}

.c-button-size-super.icon {
  width: 76px;
  padding: 0
}

.c-button-size-large {
  height: 50px;
  padding: 0 44px;
  font-size: 18px;
  border-radius: 10px
}

.c-button-size-large,.c-button-size-large>span {
  gap: 8px
}

.c-button-size-large.round {
  border-radius: 25px
}

.c-button-size-large.icon {
  width: 50px;
  padding: 0
}

.c-button-size-default {
  height: 42px;
  padding: 0 20px;
  font-size: 15px;
  border-radius: 8px
}

.c-button-size-default,.c-button-size-default>span {
  gap: 8px
}

.c-button-size-default.round {
  border-radius: 21px
}

.c-button-size-default.icon {
  width: 40px;
  padding: 0
}

.c-button-size-small {
  height: 32px;
  padding: 0 16px;
  font-size: 13px;
  border-radius: 6px
}

.c-button-size-small,.c-button-size-small>span {
  gap: 6px
}

.c-button-size-small.round {
  border-radius: 16px
}

.c-button-size-small.icon {
  width: 32px;
  padding: 0
}

.c-button-size-mini {
  height: 24px;
  padding: 0 6px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 400
}

.c-button-size-mini,.c-button-size-mini>span {
  gap: 4px
}

.c-button-size-mini.round {
  border-radius: 12px
}

.c-button-size-mini.icon {
  width: 24px;
  padding: 0
}

.c-button.disabled {
  cursor: not-allowed
}

.c-button.loading span {
  height: 0;
  overflow: hidden
}

.c-button.loading .c-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.c-button.full {
  display: flex;
  width: 100%
}

.c-image {
  background: center/cover no-repeat
}

.c-icon {
  display: block
}

.c-icon-size-super {
  width: 48px;
  height: 48px
}

.c-icon-size-large {
  width: 32px;
  height: 32px
}

.c-icon-size-default {
  width: 24px;
  height: 24px
}

.c-icon-size-small {
  width: 20px;
  height: 20px
}

.c-icon-size-mini {
  width: 16px;
  height: 16px
}

.c-icon.online {
  display: inline-block
}


.c-nodata {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 10px;
  color: #9a98b9;
  height: 240px
}

.c-nodata img {
  width: 48px;
  height: 48px
}

.c-spinner {
  display: flex;
  justify-content: center;
  align-items: center
}

.c-spinner-default {
  width: 40px;
  height: 40px
}

.c-spinner-default .c-spinner-dot {
  width: 5px;
  height: 5px;
  margin: 0 3.5px
}

.c-spinner-mini {
  width: 24px;
  height: 24px
}

.c-spinner-mini .c-spinner-dot {
  width: 3px;
  height: 3px;
  margin: 0 2.5px
}

.c-spinner-small {
  width: 32px;
  height: 32px
}

.c-spinner-small .c-spinner-dot {
  width: 4px;
  height: 4px;
  margin: 0 3px
}

.c-spinner-large {
  width: 60px;
  height: 60px
}

.c-spinner-large .c-spinner-dot {
  width: 6px;
  height: 6px;
  margin: 0 4px
}

.c-spinner-super {
  width: 76px;
  height: 76px
}

.c-spinner-super .c-spinner-dot {
  width: 8px;
  height: 8px;
  margin: 0 5px
}

.c-spinner.full {
  width: 100%;
  height: 100%
}

.c-spinner-dot {
  border-radius: 50%;
  background: #6e4827;
  animation: loading .6s ease infinite alternate
}

.c-spinner-dot:nth-child(1) {
  margin-left: 0
}

.c-spinner-dot:nth-child(2) {
  animation-delay: .3s
}

.c-spinner-dot:nth-child(3) {
  animation-delay: .6s;
  margin-right: 0
}

.Toastify__toast-icon {
  width: 24px;
  height: 24px;
  background: center/contain no-repeat
}

.Toastify__toast-icon svg {
  display: none
}

.Toastify__toast--info .Toastify__toast-icon {
  background-image: url(//rocket-dog-static.pages.dev/image/icon/info.svg)
}

.Toastify__toast--success .Toastify__toast-icon {
  background-image: url(//rocket-dog-static.pages.dev/image/icon/success.svg)
}

.Toastify__toast--warning .Toastify__toast-icon {
  background-image: url(//rocket-dog-static.pages.dev/image/icon/warning.svg)
}

.Toastify__toast--error .Toastify__toast-icon {
  background-image: url(//rocket-dog-static.pages.dev/image/icon/error.svg)
}

.Toastify__toast-body {
  color: #6e4827
}

.Toastify__toast-theme--dark {
  background: #151527
}

.Toastify__toast-container--top-right .Toastify__toast-body {
  align-items: flex-start
}

.Toastify__close-button {
  transition: all .25s ease-in-out
}

.Toastify__close-button:hover {
  transform: rotate(180deg)
}

.Toastify__progress-bar--info {
  background: #33ceff
}

.Toastify__progress-bar--success {
  background: #ff73ce
}

.Toastify__progress-bar--warning {
  background: #ffcb1e
}

.Toastify__progress-bar--error {
  background: #ff73ce
}

.c-notify-title {
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  color: #fff9
}

.c-notify-content {
  font-size: 13px;
  line-height: 22px;
  color: #9f9f9f
}

.c-notify-content span,.c-notify-content strong,.c-notify-content em {
  color: #fff9
}

.c-notify-custom {
  border-radius: 12px;
  border: 2px solid #555177;
  position: relative;
  overflow: inherit;
  background: #151527
}

.c-notify-custom .Toastify__close-button {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #555177;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center
}

.c-notify-custom .Toastify__progress-bar {
  border-radius: 0 0 12px 12px;
  height: 3px;
  left: 2px;
  right: 2px
}

.c-wallet-contact-modal {
  max-height: 160px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 18px;
  border-radius: 8px;
  overflow-y: auto;
  background-color: #000;
  padding: 8px 0 0 8px
}

.c-wallet-contact-modal button {
  font-weight: 400;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #fff9;
  background-color: #151527;
  height: 36px;
  font-size: 14px;
  padding: 0 8px
}

.c-wallet-contact-modal button img {
  height: 20px;
  width: 20px;
  border-radius: 4px
}

.c-wallet-contact-less-modal-btns {
  padding-top: 16px
}

.c-wallet-contact-less-modal-btns button {
  font-weight: 400;
  color: #fff9;
  justify-content: flex-start;
  margin-bottom: 16px
}

.c-wallet-contact-less-modal-btns button img {
  margin-right: 8px;
  height: 24px;
  width: 24px
}

.c-footer {
  border-top: 1px solid rgba(255,255,255,.4);
  padding: 24px;
  text-align: center;
  color: #fff6;
  font-size: 16px;
  margin-top: 80px
}


.c-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-right: 10px;
  height: 120px
}

.c-header>.logo a {
  display: block;
  width: 200px;
  height: 60px;
  background: url(//rocket-dog-static.pages.dev/image/logo.png) center left/contain no-repeat;
  text-indent: -9999px
}

.c-header-tools {
  display: flex;
  align-items: center;
  gap: 20px
}


.p-home-list {
  margin-top: 80px
}

.p-home-list>header {
  height: 50px;
  background: url(./assets/winning-title.svg) center/contain no-repeat;
  text-indent: -9999px;
  margin-bottom: 50px
}

.p-home-list>section {
  background: url(//rocket-dog-static.pages.dev/image/section-header.png) center top/100% auto no-repeat #171729;
  border-radius: 48px;
  padding: 2rem;
  color: #fff;
  font-size: 20px;
  font-weight: 700
}

.p-home-list>section .thead {
  font-size: 16px;
  color: #fff6;
  margin-bottom: 20px
}

.p-home-list>section .row {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.th img {
  border-radius: 50%; /* 设置边框半径为图像宽度的50%，从而创建圆形图像 */
}

.p-home-list>section .tbody {
  font-size: 20px;
  color: #fff
}

.p-home-list>section .tbody .td {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 60px
}

.p-home-list>section .tbody .td img {
  height: 24px
}

.p-home-list>section .tbody .nothing {
  gap: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 2.5em;
  padding: 20px;
  background: linear-gradient(180deg,#fef102,#ffa108);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.p-home-list>section .tbody .nothing span {
  font-size: 12px
}

.p-home-banner {
  padding: 40px
}

.p-home-banner img {
  width: 836px;
  height: 4rem;
  margin: 0 auto;
  display: block
}


.p-home-bench main {
  padding-top: 210px;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 700px
}

.p-home-bench main h3 {
  font-size: 20px;
  color: #ffffff59;
  font-weight: 400
}

.p-home-bench main em {
  display: block;
  color: #ffe7af;
  font-weight: 700;
  font-size: 52px;
  text-shadow: -1px -1px 0 #000,-2px -2px 0 #ffe7af;
  margin: 20px 0 100px;
  text-transform: uppercase
}

.p-home-bench main button {
  width: 440px;
  height: 80px;
  background: none;
  border: none;
  padding-bottom: 30px;
  color: #ffffff;
  font-weight: bolder;
  font-size: 30px;
  cursor: pointer;
  transition: all .25s ease-in-out
}

.p-home-bench main button:hover {
  letter-spacing: 1px
}


.p-home-rules header {
  height: 50px;
  background: url(./assets/rules-title.svg) center/contain no-repeat;
  text-indent: -9999px;
  margin-bottom: 50px
}

.p-home-rules article {
  background: url(//rocket-dog-static.pages.dev/image/section-header.png) center top/100% auto no-repeat #171729;
  border-radius: 48px;
  padding: 100px 62px;
  color: #fff;
  font-size: 20px;
  line-height: 3;
  font-weight: 700
}

* {
  box-sizing: border-box;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-icons a {
  margin-right: 5px;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.th {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.th img {
  width: 30%; /* 调整图像宽度为容器宽度的30% */
  height: auto; /* 高度自动，保持宽高比 */
  border-radius: 50%; /* 使图像显示为圆形 */
  margin:1rem;
}

@media screen and (max-width: 768px) {
  /* 基础样式调整 */
  body {
    font-size: 16px; /* 根据需要调整 */
    line-height: 1.5;
    color: #FFFFFF; /* 白色文字 */
    background-color: #000; /* 黑色背景 */
  }

  /* 布局和容器 */
  .web, .p-home-rules, .p-home-bench, .p-home-banner, .p-home-list, .c-header, .c-footer {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }

  /* 按钮样式调整 */
  .c-button {
    font-size: 16px; /* 字体大小 */
    border-radius: 15px; /* 圆角 */
    transition: background-color 0.3s; /* 过渡效果 */
  }

  .c-button:hover {
    background-color: #E6B800; /* 悬停时背景色 */
  }

  /* 头部样式调整 */
  .c-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: transparent; /* 透明背景 */
  }

  .c-header .logo a {
    width: 8rem;
    height: 3rem;
  }

  /* 横幅样式调整 */
  .p-home-banner {
    padding: 0.2rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .p-home-banner img {
    width: 80%;
    height: auto;
  }

  /* 工作台样式调整 */
  .p-home-bench main {
    padding-top: 8.5rem;
    height: 27rem;
    background-size: cover;
    background-position: center;
  }

  .p-home-bench main h3 {
    font-size: 20px; /* 根据需要调整 */
  }

  .p-home-bench main em {
    font-size: 2rem;
    margin: .2rem 0 2.5rem;
    color: #FFE7AF; /* 黄色文字 */
    text-shadow: -1px -1px 0 #000, -2px -2px 0 #FFE7AF; /* 文字阴影 */
    text-transform: uppercase;
  }

  .p-home-bench main button {
    width: 100%;
    height: 50px; /* 根据需要调整 */
    font-size: 16px; /* 字体大小 */
    padding-bottom: 0;
  }

  /* 规则区域样式调整 */
  .p-home-rules article {
    padding: 20px;
    background-size: cover;
    background-position: center;
    font-size: .5rem;
  }

  .p-home-rules article h3 {
    font-size: 20px; /* 根据需要调整 */
  }

  /* 底部样式调整 */
  .c-footer {
    padding: 10px;
    font-size: 14px;
    margin-top: 20px;
    background-color: transparent; /* 透明背景 */
    text-align: center;
  }

  /* 背景图片 */
  body {
    background-image: url(//rocket-dog-static.pages.dev/image/m-bg-t.jpg), url(//rocket-dog-static.pages.dev/image/m-bg-b.png);
    background-size: 100% auto, 100% auto;
    background-position: top center, bottom center;
    background-repeat: no-repeat;
    background-attachment:local;
  }

  .th img {
    width: 100%; /* 在小屏幕上将图像宽度调整为容器宽度的100% */
    height: auto; /* 高度自动，保持宽高比 */
    border-radius: 50%; /* 使图像显示为圆形 */
  }
}